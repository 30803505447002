import React, { useState } from 'react'
import "./paystack.css"
import { v4 as uuidv4 } from 'uuid';
import { PaystackButton } from "react-paystack"
import axios from '../../axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Paystack() {
    const [amount, setAmount] = useState(null)
    const email = localStorage.getItem("user_email")

    const showRequestSuccess = (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_CENTER,
        });
    };

    const showRequestFailed = (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            style: {
                backgroundColor: "black",
                color: "white",
            },
        });
    };

    //const publicKey = "pk_test_4f31c1621f7d6c77370c320e0b860779f763e415";
    const publicKey = "pk_live_45cb4ea56402832c4859a716fafa44439ef2c6a5"
    const reference = uuidv4();
    const config = { reference, email, amount, publicKey };

    const handlePaystackSuccessAction = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        const amo = amount / 100
        console.log(reference);
        console.log(amo);
        let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
        axios.post("/portfolio/paystack-receipt/", { reference: reference.reference, transaction: reference.transaction, amount: amo, device_type:deviceType }).then(res => {
            console.log(res)
            showRequestSuccess("Payment was successful")

        }).catch(err => {
            console.log(err)
            showRequestFailed("Server error, do not try payment again.")
        })
    };

    // you can call this function anything
    const handlePaystackCloseAction = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }


    const componentProps = {
        ...config,
        text: 'Make Payment',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };

    return (
        <div className='paystack_main_container'>
            <ToastContainer />
            <div className='paystack_container'>
                <h1>Paystack Payment</h1>
                <p>Input the amount you want to fund and click the button below.</p>
                <div className='paystack_form'>
                    <div>
                        <label className='paystack_label' htmlFor="">Amount</label>
                        <input placeholder='0.0' onChange={e => setAmount(e.target.value * 100)} className='paystack_amount' type="number" name="" id="" />
                    </div>
                </div>
                {/* <div
                    onClick={() => initializePayment(onSuccess, onClose)}
                    className="paystack_paymentButton"
                >
                    <p>Make Payment</p>
                </div> */}
                <PaystackButton {...componentProps} className='paystack_paymentButton' />
            </div>
        </div>
    )
}

export default Paystack
