/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import "./holdings.css";
import { HoldingsTable, PieChartHoldings } from "../../components";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const Holdings = () => {
  const isMobile = window.innerWidth < 426;
  const [holdings, setHoldings] = useState([]);
  const liveDemo = useSelector((state) => state.page.liveDemo);
  const [balance, setBalance] = useState(0);
  const [closed, setClosed] = useState(0);
  const [floating, setFloating] = useState(0);
  const [equity, setEquity] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [equityInfo, setEquityInfo] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState(false);
  const [closingInfo, setClosingInfo] = useState(false);
  const [floatingInfo, setFloatingInfo] = useState(false);
  const account = useSelector((state) => state.account);
  // console.log(pieChartValues);

  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(() => {
    if (!params.get("trader_email")) {
      axios
        .get(`execution/demo/holding/${account.acc_type}/${account.id}`)
        .then((res) => {
          console.log(res);
          setBalance(res.data.balance);
          setEquity(res.data.equity);
          setFloating(res.data.floating_pl);
          setClosed(res.data.closed_pl);
          setHoldings(res.data.holdings);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `execution/demo/support/holding/${account.acc_type}/${account.id}/`
        )
        .then((res) => {
          console.log(res);
          setBalance(res.data.balance);
          setEquity(res.data.equity);
          setFloating(res.data.floating_pl);
          setClosed(res.data.closed_pl);
          setHoldings(res.data.holdings);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [account.acc_type, account.id, liveDemo, params, refresh]);

  return (
    <div
      className="holdingCardContainer"
      onClick={() => {
        setEquityInfo(false);
        setBalanceInfo(false);
        setClosingInfo(false);
        setFloatingInfo(false);
      }}
    >

      <div className="holdingCard over bg-white md:bg-transparent">
        <div className="holdingCardTop">
          {isMobile ? (
            <>
              {/* <div className="holdingCardRight">
                <div className="pieContainer">
                  <div className="pieChartContainer">
                    {pieChartData.length === 0 && (
                      <p>You do not have any stock in your portfolio</p>
                    )}
                    <PieChartHoldings
                      labels={pieChartData}
                      data={pieChartValues}
                    />
                  </div>
                </div>
              </div> */}
              <div className="holdingCardLeft !justify-center mb-2 flex-col md:flex-row">
                <div className="portfolioValue w-full md:w-1/2 max-w-[300px]">
                  <div className="app__flex w-full relative mb-1">
                    <p className="font-normal">Balance</p>
                    <span
                      className="absolute top-1/2   -translate-y-1/2 right-0 "
                      data-tooltip-id="portfolioValue"
                      data-tooltip-html="Balance This is the value of your<br /> account, irrespective of<br /> fluctuations in market<br /> prices"
                    >
                      <AiOutlineExclamationCircle size={16} color="#00BA77" />
                      <Tooltip id="portfolioValue" />
                    </span>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p>{balance?.toLocaleString() || 0}</p>
                    </div>
                  </div>
                </div>
                <div className="totalInvested w-full md:w-1/2 max-w-[300px]">
                  <div className="app__flex w-full relative mb-1">
                    <p className="font-normal">Equity</p>
                    <span
                      className="absolute top-1/2   -translate-y-1/2 right-0 "
                      data-tooltip-id="Equity"
                      data-tooltip-html="This is the real-time value of <br /> a your account, which <br />changes as the market <br />prices fluctuate. "
                    >
                      <AiOutlineExclamationCircle size={16} color="#00BA77" />
                      <Tooltip id="Equity" />
                    </span>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p>{equity?.toLocaleString() || 0}</p>
                    </div>
                  </div>
                </div>
                <div className="profitLoss w-full md:w-1/2 max-w-[300px]">
                  <div className="app__flex w-full relative mb-1">
                    <p className="font-normal">Closed P/L</p>
                    <span
                      className="absolute top-1/2   -translate-y-1/2 right-0 "
                      data-tooltip-id="Closed P/L"
                      data-tooltip-html="This is the sum of the <br />profits and losses of all<br />closed positions incured on<br />your account."
                    >
                      <AiOutlineExclamationCircle size={16} color="#00BA77" />
                      <Tooltip id="Closed P/L" />
                    </span>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p style={{ color: closed < 0 && "red" }}>
                        {closed?.toLocaleString() || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="profitLoss w-full md:w-1/2 max-w-[300px]">
                  <div className="app__flex w-full relative mb-1">
                    <p className="font-normal">Floating P/L</p>
                    <span
                      className="absolute top-1/2   -translate-y-1/2 right-0 "
                      data-tooltip-id="Floating P/L"
                      data-tooltip-html="This is the sum of the<br />profits and losses of all<br />open positions incured on<br />your account."
                    >
                      <AiOutlineExclamationCircle size={16} color="#00BA77" />
                      <Tooltip id="Floating P/L" />
                    </span>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p style={{ color: floating < 0 && "red" }}>
                        {floating?.toLocaleString() || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="holdingCardLeft !justify-center mb-2 flex-col md:flex-row">
                <div
                  className="portfolioValue  w-full md:w-1/2 max-w-[300px]"
                  style={{ position: "relative" }}
                >
                  <div className="app__flex w-full relative mb-1">
                    <p>Balance</p>
                    <div className="headingIcon !top-1/2   !-translate-y-1/2 !right-0">
                      <IoIosInformationCircleOutline
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log("Icon clcked");
                          setEquityInfo(false);
                          setBalanceInfo(!balanceInfo);
                          setClosingInfo(false);
                          setFloatingInfo(false);
                        }}
                      />
                      {balanceInfo && (
                        <div
                          style={{
                            color: "#353535",
                            position: "absolute",
                            top: 15,
                            left: -160,
                            zIndex: 10,
                            width: "154px",
                            borderRadius: "0px 5px 5px 5px",
                            background: "white",
                            fontSize: "10px",
                            padding: "8px",
                            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          This is the value of your account, irrespective of
                          fluctuations in market prices
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p>{balance?.toLocaleString() || 0}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="totalInvested  w-1/2 max-w-[300px]"
                  style={{ position: "relative" }}
                >
                  <div className="app__flex w-full relative mb-1">
                    <p>Equity</p>
                    <div className="headingIcon !top-1/2   !-translate-y-1/2 !right-0">
                      <IoIosInformationCircleOutline
                        onClick={(e) => {
                          e.stopPropagation();
                          setEquityInfo(!equityInfo);
                          setBalanceInfo(false);
                          setClosingInfo(false);
                          setFloatingInfo(false);
                        }}
                      />
                      {equityInfo && (
                        <div
                          style={{
                            color: "#353535",
                            position: "absolute",
                            top: 15,
                            left: -160,
                            zIndex: 10,
                            width: "154px",
                            borderRadius: "0px 5px 5px 5px",
                            background: "white",
                            fontSize: "10px",
                            padding: "8px",
                            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          This is the real-time value of a your account, which
                          changes as the market prices fluctuate.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p>{equity?.toLocaleString() || 0}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="profitLoss  w-1/2 max-w-[300px]"
                  style={{ position: "relative" }}
                >
                  <div className="app__flex w-full relative mb-1">
                    <p>Closed P/L</p>
                    <div className="headingIcon !top-1/2   !-translate-y-1/2 !right-0">
                      <IoIosInformationCircleOutline
                        onClick={(e) => {
                          e.stopPropagation();
                          setEquityInfo(false);
                          setBalanceInfo(false);
                          setClosingInfo(!closingInfo);
                          setFloatingInfo(false);
                        }}
                      />
                      {closingInfo && (
                        <div
                          style={{
                            color: "#353535",
                            position: "absolute",
                            top: 15,
                            left: -160,
                            zIndex: 10,
                            width: "154px",
                            borderRadius: "0px 5px 5px 5px",
                            background: "white",
                            fontSize: "10px",
                            padding: "8px",
                            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          This is the sum of the profits and losses of all
                          closed positions incured on your account.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p style={{ color: closed < 0 && "red" }}>
                        {closed?.toLocaleString() || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="profitLoss  w-1/2 max-w-[300px]"
                  style={{ position: "relative" }}
                >
                  <div className="app__flex w-full relative mb-1 mx-2">
                    <p>Floating P/L</p>
                    <div className="headingIcon !top-1/2   !-translate-y-1/2 !right-0">
                      <IoIosInformationCircleOutline
                        onClick={(e) => {
                          e.stopPropagation();
                          setEquityInfo(false);
                          setBalanceInfo(false);
                          setClosingInfo(false);
                          setFloatingInfo(!floatingInfo);
                        }}
                      />
                      {floatingInfo && (
                        <div
                          style={{
                            color: "#353535",
                            position: "absolute",
                            top: 15,
                            right: 10,
                            zIndex: 10,
                            width: "154px",
                            borderRadius: "5px 0px 5px 5px",
                            background: "white",
                            fontSize: "10px",
                            padding: "8px",
                            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          This is the sum of the profits and losses of all open
                          positions incured on your account.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="no-text">
                    <div className="n-text">
                      <p>N</p>
                    </div>
                    <div className="o-text">
                      <p style={{ color: floating < 0 && "red" }}>
                        {floating?.toLocaleString() || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="holdingCardRight">
                <div className="pieContainer">
                  <div className="pieChartContainer">
                    {pieChartData.length === 0 && (
                      <p>You do not have any stock in your portfolio</p>
                    )}
                    <PieChartHoldings
                      labels={pieChartData}
                      data={pieChartValues}
                    />
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div>
        <div className="holdingTable">
          <HoldingsTable
            data={holdings}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default Holdings;
