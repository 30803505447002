/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./addfunds.css";
import BankTransferImg from "../../assets/images/Group 259.svg";
import PayStackImg from "../../assets/images/Group 263.svg";
import { Link } from "react-router-dom";

const AddFunds = () => {
  return (
    <div className="rounded-card-container">
      <div className="rounded-card">
        <div className="addFundDiv">
          <div className="addFundsText">
            <h2>Add Funds</h2>
          </div>
          <div className="addFundsBottom">
            <Link to="/wallet/addfunds/banktransfer">
              <div className="bankTransferCard">
                <img src={BankTransferImg} alt="BankTransfer" />
                <a href="#">Bank Transfer</a>
                <p style={{ textAlign: "center" }}>
                  Fund your Hamma account via bank transfer.
                </p>
              </div>
            </Link>
            <Link to="/wallet/addfunds/paystack">
              <div className="payStackCard">
                <img src={PayStackImg} alt="Pay stack" />
                <p style={{ textAlign: "center" }}>
                  Fund your Hamma account via paystack.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFunds;
