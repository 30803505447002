/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import "./privacysecurity.css";
import { Link } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import QRCode from "react-qr-code";

const PrivacySecurity = () =>
  // {
  //   changePassword,
  //   setChangePassword,
  //   handleChangePasswordComp,
  // }
  {
    // const [textMessage, setTextMessage] = useState(false);
    const [email, setEmail] = useState(true);
    const [authentication, setAuthentication] = useState(false);
    const changePassword = useSelector((state) => state.page.changePassword);
    const dispatch = useDispatch();
    const refresh = useSelector((state) => state.refresh);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [value, setValue] = useState(null);
    console.log(value);
    const [loginToken, setLoginToken] = useState({
      first: "",
      second: "",
      third: "",
      forth: "",
      fifth: "",
      sixth: "",
      // seventh: "",
      // eight: "",
    });
    const [userEmail, setUserEmail] = useState("");
    console.log(userEmail);

    useEffect(() => {
      axios
        .get("auth/profile/")
        .then((res) => {
          console.log(res);
          if (res.data.user.twofa_type === "sms") {
            // setTextMessage(true);
            setEmail(false);
            setAuthentication(false);
            setUserEmail(res.data.user.email);
          } else if (res.data.user.twofa_type === "email") {
            // setTextMessage(false);
            setEmail(true);
            setAuthentication(false);
            setUserEmail(res.data.user.email);
          } else {
            // setTextMessage(false);
            setEmail(false);
            setAuthentication(true);
            setUserEmail(res.data.user.email);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, [refresh]);

    const showRequestSuccess = (message) => {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    };

    const showRequestFailed = (message) => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
    };

    const handleChangePasswordComp = () => {
      dispatch({ type: "setChangePassword", payload: true });
    };

    const handle2faChange = async (action) => {
      setOpen(true);
      let deviceType =
        window.innerWidth <= 768
          ? "Mobile"
          : window.innerWidth <= 1024
          ? "Tablet"
          : "Desktop";
      try {
        axios
          .post("auth/2fa_on_off/", {
            action,
            device_type: deviceType,
          })
          .then((res) => {
            console.log(res);
            setOpen(false);
            if (
              res.data.detail ===
              "2FA turn on successsfully, but you must provide token for your authentication app to activate."
            ) {
              setValue(res.data.qr_url);
              setOpen2(true);
            } else {
              dispatch({ type: "refresh" });
              showRequestSuccess("Successfully changed 2fa.");
            }
          })
          .catch((err) => {
            console.log(err);
            setOpen(false);
            showRequestFailed("Failed to change 2fa.");
          });
      } catch (error) {
        setOpen(false);
        console.log(error);
      }
    };

    const LoginVerify = async () => {
      let deviceType =
        window.innerWidth <= 768
          ? "Mobile"
          : window.innerWidth <= 1024
          ? "Tablet"
          : "Desktop";
      axios
        .post("auth/activate-authapp/", {
          user_email: userEmail,
          token:
            loginToken.first +
            loginToken.second +
            loginToken.third +
            loginToken.forth +
            loginToken.fifth +
            loginToken.sixth,
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res);
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          // window.location.href = "/"
          dispatch({ type: "refresh" });
          setOpen2(false);
          showRequestSuccess("Successfully changed 2fa.");
        })
        .catch((err) => {
          console.log(err);
          showRequestFailed("Failed to change 2fa.");
          // alert("Token verification failed, check token and try again")
        });
    };

    const onCloseModal = () => setOpen(false);
    const onCloseModal1 = () => setOpen2(false);
    const closeIcon = (
      <svg
        fill="currentColor"
        style={{ display: "none" }}
        viewBox="0 0 20 20"
        width={28}
        height={28}
      >
        <path
          fillRule="evenodd"
          d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
          clipRule="evenodd"
        ></path>
      </svg>
    );

    return (
      <>
        {changePassword ? (
          <ChangePassword />
        ) : (
          <div className="privacySecurityCard ">
            <ToastContainer />
            <div className="twoFactorAuthentication mb-4">
              <h3>Two-Factor Authentication</h3>
              <p>
                Select your preferred option for receiving one time passwords
                (OTPs)
              </p>
            </div>
            <div className="privacySecurityMidDiv mb-4">
              <div className="textMessageDiv">
                <h3>Email</h3>
                <p>
                  Use your email address to receive verification codes for added
                  protection.
                </p>
              </div>
              {email ? (
                <div
                  onClick={() => handle2faChange("email")}
                  className="greenDiv"
                >
                  <div className="whiteDiv"></div>
                </div>
              ) : (
                <div
                  onClick={() => handle2faChange("email")}
                  className="greyDiv"
                >
                  <div className="greyWhiteDiv"></div>
                </div>
              )}
            </div>
            {/* <div className="privacySecurityMidDiv mb-4">
                                                <div className="textMessageDiv">
                                                        <h3>Text message (SMS)</h3>
                                                        <p>
                                                                Use text messages to receive verification codes for added
                                                                protection.
                                                        </p>
                                                </div>
                                                {textMessage ? (
                                                        <div
                                                                onClick={() => handle2faChange("sms")}
                                                                className="greenDiv"
                                                        >
                                                                <div className="whiteDiv"></div>
                                                        </div>
                                                ) : (
                                                        <div onClick={() => handle2faChange("sms")} className="greyDiv">
                                                                <div className="greyWhiteDiv"></div>
                                                        </div>
                                                )}
                                        </div> */}
            <div className="privacySecurityMidDiv mb-4">
              <div className="textMessageDiv">
                <h3>Authentication app (Recommended)</h3>
                <p>
                  Use Google authenticator to generate verification codes for
                  secure login
                </p>
              </div>
              {authentication ? (
                <div
                  onClick={() => handle2faChange("authapps")}
                  className="greenDiv"
                >
                  <div className="whiteDiv"></div>
                </div>
              ) : (
                <div
                  onClick={() => handle2faChange("authapps")}
                  className="greyDiv"
                >
                  <div className="greyWhiteDiv"></div>
                </div>
              )}
            </div>

            <div className="changePasswordDiv">
              <h3>Change password</h3>
              <Link
                onClick={handleChangePasswordComp}
                to="/privacysecurity/changepassword"
              >
                Change your password here
              </Link>
            </div>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              closeIcon={closeIcon}
            >
              <h2>Changing 2fa...</h2>
            </Modal>
            <Modal
              open={open2}
              onClose={onCloseModal1}
              center
              closeIcon={closeIcon}
            >
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 300,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={value}
                  viewBox={`0 0 256 256`}
                  // title="Scan the QR code with your auth app."
                />
                <h1 style={{ fontSize: "16px" }}>
                  Scan the QR code with you authentication app, then provide the
                  token in the fields below and click send.
                </h1>
                <div>
                  <div className="private_token_container">
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, first: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, second: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, third: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, forth: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, fifth: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    <input
                      onChange={(e) =>
                        setLoginToken({ ...loginToken, sixth: e.target.value })
                      }
                      maxLength={1}
                      type="text"
                      className="border border-black rounded-[4px]"
                    />
                    {/* <input onChange={(e) => setLoginToken({ ...loginToken, seventh: e.target.value })} maxLength={1} type="text" className='register_token_input' />
                      <input onChange={(e) => setLoginToken({ ...loginToken, eight: e.target.value })} maxLength={1} type="text" className='register_token_input' /> */}
                  </div>
                  <button
                    onClick={LoginVerify}
                    style={{
                      margin: "10px auto",
                      background: "#00ba77",
                      border: "none",
                      color: "white",
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  };

export default PrivacySecurity;
