/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./header.css";
import { FaChevronDown } from "react-icons/fa";
import profilePicture from "../../assets/images/default_image.jpeg";
// import bellIcon from "../../assets/images/bellicon.svg";
import { IoIosMenu } from "react-icons/io";
import { GoBell } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profileIcon from "../../assets/images/menu/profile-icon.svg";
// import supportIcon from "../../assets/images/menu/support-icon.svg";
import logoutIcon from "../../assets/images/menu/logout-icon.svg";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardBackspace } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
// import { io } from "socket.io-client";
import { w3cwebsocket } from "websocket";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Menu, Transition } from "@headlessui/react"
// import { Menu, Transition } from '@headlessui/react';

function ConvertData(params) {
  const date = new Date(params);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
}

const Header = ({ toggleMenu }) => {
  // const [send, setSend] = useState(false)
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [backButton, setBackButton] = useState(false);
  const [openAccountsDropDown, setOpenAccountsDropDown] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useSelector((state) => state.page.history);
  const profile = useSelector((state) => state.page.profile);
  const account = useSelector((state) => state.account);
  const openaccounts = useSelector((state) => state.page.openAccounts);
  const opennotifs = useSelector((state) => state.page.openNotif);
  const changePassword = useSelector((state) => state.page.changePassword);
  const walletPage = useSelector((state) => state.page.walletPage);
  const lockup = useSelector((state) => state.page.lockup);
  const [accounts, setAccounts] = useState([]);
  const [accountsLive, setAccountsLive] = useState([]);
  // const liveDemo = useSelector((state) => state.page.liveDemo);
  const accountInformation = useSelector(
    (state) => state.page.accountInformation
  );
  const addBank = useSelector((state) => state.page.addBank);
  console.log("dropdownMenu", dropdownMenu);
  const screenWidth = window.innerWidth;
  const expanded = useSelector((state) => state.page.expanded);
  const [open, setOpen] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const refresh = useSelector((state) => state.refresh);
  console.log(account);

  const [profileImg, setProfileImg] = useState("");
  console.log(notifs);

  const popupRef = useRef(null);
  const dropDownRef = useRef(null);

  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const FindStock = (e) => {
    dispatch({ type: "searchStock", payload: e.toLowerCase() });
  };

  const handleTabClick = (path) => {
    dispatch({ type: "setProfileComp", payload: path });

    if (path === "/accountinformation") {
      dispatch({
        type: "setAccountInformation",
        payload: "accountInformation",
      });
    } else if (path === "/payment") {
      dispatch({ type: "setAddBank", payload: false });
    } else if (path === "/privacysecurity") {
      dispatch({ type: "setChangePassword", payload: false });
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropdownMenu(false);
      }
    };

    if (dropdownMenu) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownMenu, setDropdownMenu]);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (params.get("trader_email")) {
        axios
          .get(
            `/execution/demo/support/accounts/${params.get("trader_email")}/`
          )
          .then((res) => {
            console.log(res);
            setAccounts(res.data);

            if (
              res.data?.competition_account &&
              res.data?.competition_account?.active === true
            ) {
              dispatch({
                type: "select_account",
                payload: {
                  acc_id: res.data.competition_account.account_id,
                  id: res.data.competition_account.id,
                  acc_type: res.data.competition_account.account_type,
                  acc_name: res.data.competition_account.account_name,
                },
              });
            } else if (res.data?.demo_accounts?.length > 0) {
              dispatch({
                type: "select_account",
                payload: {
                  acc_id: res.data.demo_accounts[0].account_id,
                  id: res.data.demo_accounts[0].id,
                  acc_type: res.data.demo_accounts[0].account_type,
                  acc_name: res.data.demo_accounts[0].account_name,
                },
              });
            } else {
              dispatch({
                type: "select_account",
                payload: {
                  acc_id: null,
                  id: null,
                  acc_type: "",
                  acc_name: "",
                },
              });
              alert("There is no user with this email");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .get("/execution/demo/accounts/")
          .then((res) => {
            console.log(res.data);
            setAccounts(res.data);
            if (
              res.data.competition_account &&
              res.data.competition_account.active === true &&
              !account.id
            ) {
              dispatch({
                type: "select_account",
                payload: {
                  acc_id: res.data.competition_account.account_id,
                  id: res.data.competition_account.id,
                  acc_type: res.data.competition_account.account_type,
                  acc_name: res.data.competition_account.account_name,
                },
              });
            } else {
              if (!account.id) {
                dispatch({
                  type: "select_account",
                  payload: {
                    acc_id: res.data.demo_accounts[0].account_id,
                    id: res.data.demo_accounts[0].id,
                    acc_type: res.data.demo_accounts[0].account_type,
                    acc_name: res.data.demo_accounts[0].account_name,
                  },
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    const fetchAccountsLive = async () => {
      axios
        .get("/execution/live/accounts/")
        .then((res) => {
          console.log(res);
          setAccountsLive(res.data.accounts);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (localStorage.getItem("access_token")) {
      fetchAccounts();
      fetchAccountsLive();
    }
  }, [refresh]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [open, setOpen]);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      axios
        .get("auth/profile/")
        .then((res) => {
          console.log(res);
          if (res.data.detail.profile_img) {
            setProfileImg(res.data.detail.profile_img);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    setBackButton(false);
    const handleResize = () => {
      if (screenWidth > 425) {
        console.log("Its desktop screen", pathname);
        if (pathname === "/profile") {
          dispatch({ type: "setProfileComp", payload: pathname });
          setHeaderTitle("My Account");
        } else if (pathname === "/accountinformation") {
          dispatch({ type: "setProfileComp", payload: pathname });
          dispatch({
            type: "setAccountInformation",
            payload: "accountInformation",
          });
          setHeaderTitle("Account Information");
        } else if (pathname === "/accountinformation/proofofid") {
          dispatch({ type: "setProfileComp", payload: "/accountinformation" });

          dispatch({
            type: "setAccountInformation",
            payload: "ProofOfID",
          });
          setBackButton(true);
          setHeaderTitle("Proof of ID");
        } else if (pathname === "/accountinformation/proofofaddress") {
          dispatch({ type: "setProfileComp", payload: "/accountinformation" });
          dispatch({
            type: "setAccountInformation",
            payload: "ProofOfAddress",
          });
          setBackButton(true);
          setHeaderTitle("Proof of Address");
        } else if (pathname === "/accountinformation/uploadphotograph") {
          dispatch({ type: "setProfileComp", payload: "/accountinformation" });
          dispatch({
            type: "setAccountInformation",
            payload: "UploadPhotograph",
          });
          setBackButton(true);
          setHeaderTitle("Upload Photograph");
        } else if (pathname === "/privacysecurity") {
          dispatch({ type: "setProfileComp", payload: pathname });
          setHeaderTitle("Privacy Security");
        } else if (pathname === "/privacysecurity/changepassword") {
          dispatch({ type: "setProfileComp", payload: "/privacysecurity" });
          dispatch({ type: "setChangePassword", payload: true });
          setBackButton(true);
          setHeaderTitle("Change Password");
        } else if (pathname === "/helpsupport") {
          setHeaderTitle("Help Support");
        } else if (pathname === "/payment") {
          dispatch({ type: "setProfileComp", payload: pathname });
          setHeaderTitle("Payment");
        } else if (pathname === "/payment/addbank") {
          dispatch({ type: "setAddBank", payload: true });
          setBackButton(true);
          setHeaderTitle("Add Bank");
        } else if (pathname === "/support") {
          setHeaderTitle("Support");
        } else if (pathname === "/support/faq") {
          setHeaderTitle("FAQs");
          setBackButton(true);
        } else if (pathname === "/support/self-help") {
          setHeaderTitle("Self-Help Guide");
          setBackButton(true);
        } else if (pathname === "/support/referral") {
          setHeaderTitle("Referral Program");
          setBackButton(true);
        }
      } else {
        console.log("Its mobile screen");
        if (pathname === "/profile") {
          setHeaderTitle("My Account");
        } else if (pathname === "/accountinformation") {
          setBackButton(true);
          setHeaderTitle("Account Information");
        } else if (pathname === "/privacysecurity") {
          setBackButton(true);
          setHeaderTitle("Privacy Security");
        } else if (pathname === "/helpsupport") {
          setBackButton(true);
          setHeaderTitle("Help Support");
        } else if (pathname === "/payment") {
          setBackButton(true);
          setHeaderTitle("Payment");
        } else if (pathname === "/support") {
          setHeaderTitle("Support");
        } else if (pathname === "/support/faq") {
          setHeaderTitle("FAQs");
          setBackButton(true);
        } else if (pathname === "/support/self-help") {
          setHeaderTitle("Self-Help Guide");
          setBackButton(true);
        } else if (pathname === "/support/referral") {
          setHeaderTitle("Referral Program");
          setBackButton(true);
        }
      }

      if (pathname === "/alert" || pathname === "/alertmanual") {
        setHeaderTitle("Alert");
      } else if (pathname === "/wallet/addfunds") {
        setBackButton(true);
        setHeaderTitle("Add Funds");
      } else if (pathname === "/wallet/withdraw") {
        setBackButton(true);
        setHeaderTitle("Withdraw");
      } else if (pathname.startsWith("/wallet/savinglock")) {
        setBackButton(true);
        setHeaderTitle("Invest");
      } else if (pathname.startsWith("/wallet/addfunds/banktransfer")) {
        setBackButton(true);
        setHeaderTitle("Bank Transfer");
      } else if (pathname.startsWith("/wallet/addfunds/paystack")) {
        setBackButton(true);
        setHeaderTitle("Paystack Payment");
      } else if (pathname.startsWith("/wallet/withdraw/otp")) {
        setBackButton(true);
        setHeaderTitle("Enter OTP");
      } else if (pathname === "/holdings") {
        setHeaderTitle("Holdings");
      } else if (pathname === "/" || pathname === "/technicals") {
        setHeaderTitle("");
      }
      // else if (pathname === "/apps") {
      //   setHeaderTitle("Apps");
      // }
    };
    handleResize();

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pathname, screenWidth]);

  const dropdown = [
    {
      name: "Profile",
      img: profileIcon,
      path: "/profile",
    },
    // {
    //   name: "Support",
    //   img: supportIcon,
    //   path: "/support",
    // },
    {
      name: "Log Out",
      img: logoutIcon,
      path: "/logout",
    },
  ];

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (lockup) {
      dispatch({ type: "setLockup", payload: false });
      setBackButton(false);
    } else if (screenWidth < 426) {
      if (history) {
        dispatch({ type: "setHistory", payload: false });
        setBackButton(false);
        setHeaderTitle("Alert");
      } else if (profile) {
        dispatch({ type: "setProfile", payload: false });
        setBackButton(false);
        setHeaderTitle("My Account");
      } else if (changePassword) {
        dispatch({ type: "setChangePassword", payload: false });
        setBackButton(true);
        navigate(-1);
        setHeaderTitle("Privacy Security");
      } else if (addBank) {
        dispatch({ type: "setAddBank", payload: false });
        setBackButton(true);
        navigate(-1);
        setHeaderTitle("Payment");
      } else if (accountInformation === "accountInformation") {
        dispatch({ type: "setAccountInformation", payload: "" });
        setBackButton(false);
        navigate(-1);
        setHeaderTitle("My Account");
      } else if (accountInformation === "ProofOfID") {
        dispatch({
          type: "setAccountInformation",
          payload: "accountInformation",
        });
        navigate(-1);
        setHeaderTitle("Account Information");
      } else if (accountInformation === "ProofOfAddress") {
        dispatch({
          type: "setAccountInformation",
          payload: "accountInformation",
        });
        navigate(-1);
        setHeaderTitle("Account Information");
      } else if (accountInformation === "UploadPhotograph") {
        dispatch({
          type: "setAccountInformation",
          payload: "accountInformation",
        });
        navigate(-1);
        setHeaderTitle("Account Information");
      } else {
        navigate(-1);
      }
    } else {
      if (pathname === "/privacysecurity/changepassword") {
        dispatch({ type: "setChangePassword", payload: false });
        setBackButton(false);
        setHeaderTitle("Privacy Security");
      } else if (pathname === "/payment/addbank") {
        dispatch({ type: "setAddBank", payload: false });
        setBackButton(false);
        setHeaderTitle("Payment");
      }
      navigate(-1);
    }
  };

  // const isHomePge = pathname === "/";
  const isMarketsPage = pathname === "/";

  useEffect(() => {
    if (screenWidth < 426) {
      if (history) {
        setHeaderTitle("History");
        setBackButton(true);
      } else if (!history && pathname === "/alertmanual") {
        setHeaderTitle("Alert");
        setBackButton(false);
      } else if (profile) {
        setBackButton(true);
        setHeaderTitle("Profile");
      } else if (changePassword) {
        setBackButton(true);
        setHeaderTitle("Change Password");
      } else if (addBank) {
        setBackButton(true);
        setHeaderTitle("Add Bank");
      } else if (accountInformation === "accountInformation") {
        setBackButton(true);
        setHeaderTitle("Account Information");
      } else if (accountInformation === "ProofOfID") {
        setBackButton(true);
        setHeaderTitle("Proof of ID");
      } else if (accountInformation === "ProofOfAddress") {
        setBackButton(true);
        setHeaderTitle("Proof of Address");
      } else if (accountInformation === "UploadPhotograph") {
        setBackButton(true);
        setHeaderTitle("Upload Photograph");
      }
    } else {
    }
    if (walletPage === "wallet" && pathname === "/wallet") {
      setBackButton(false);
      setHeaderTitle("My Wallet");
    } else if (walletPage === "savings" && !lockup && pathname === "/wallet") {
      setBackButton(false);
      setHeaderTitle("Savings");
    } else if (walletPage === "savings" && lockup && pathname === "/wallet") {
      setBackButton(true);
      setHeaderTitle("Savings History");
    }
  }, [
    history,
    profile,
    changePassword,
    addBank,
    accountInformation,
    screenWidth,

    isMarketsPage,
    walletPage,
    lockup,
    pathname,
  ]);

  const handleDropDownClick = (item) => {
    setDropdownMenu(false);
    if (item.path === "/profile") {
      if (window.innerWidth < 426) {
        dispatch({ type: "setProfile", payload: true });
      } else {
        dispatch({ type: "setProfileComp", payload: item.path });
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    console.log(token);
    const client = new w3cwebsocket(
      `wss://api-hamma-f0bcaabf77ea.herokuapp.com/ws/notifications/?token=${token}`
    );
    // const socket = io(`ws://api-hamma-f0bcaabf77ea.herokuapp.com//ws/notifications/?token={${token}}`)
    // console.log(socket.on("message", (msg) => {
    //   console.log(msg)
    // }))
    // console.log(client)

    client.onopen = () => {
      console.log("Websocket client connected");
    };

    client.onmessage = (message) => {
      const receivedNotifs = JSON.parse(message.data);
      console.log("Notifs", receivedNotifs);
      if (Array.isArray(receivedNotifs.message)) {
        console.log("Array Notif", receivedNotifs.message);
        setNotifs(receivedNotifs.message);
      } else {
        console.log("Objects Notif", receivedNotifs.message);
      }
      // if (notifs.length > 0) {
      //   if (receivedNotifs.message.notification_id) {
      //     console.log("called now");
      //     setNotifs([...notifs, receivedNotifs.message]);
      //   } else {
      //     console.log("called now");
      //     setNotifs([...notifs, receivedNotifs.message[0]]);
      //   }
      // } else {
      //   if (receivedNotifs.message.length > 0) {
      //     console.log("called");
      //     setNotifs([receivedNotifs.message[0]]);
      //   }
      // }
    };
  }, [open, refresh]);

  // const handleLiveDemo = (status) => {
  //   dispatch({ type: "setLiveDemo", payload: status });
  // };
  const handleConfirm = (e) => {
    // setActiveButton(e);
    // setPopup(e);
    dispatch({ type: "setConfirm", payload: false });
  };

  const handleConfirmTrue = (e) => {
    // setActiveButton(e);
    // setPopup(e);
    dispatch({ type: "setConfirm", payload: true });
  };

  // console.log(notifs);

  const marknotif = () => {
    // let data = notifs.map((n) => `${n.notification_id}-${n.user_id}`);
    axios.post("update-notification/").then((res) => {
      console.log(res);
      setNotifs([]);
    });
  };
  // console.log(window.location.pathname.split("/")[1])

  const buttonClick = async (buttonName) => {
    // console.log(buttonName)
    // console.log(page.toLowerCase)
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    try {
      axios
        .post("/portfolio/button-click/", {
          button_name: `${buttonName}`,
          page: window.location.pathname.split("/")[1] || "home",
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  return (
    <div className="header">
      <ToastContainer />
      <div className="left-header">
        <button
          className="menu-button rounded-[6px] transition duration-300"
          onClick={toggleMenu}
        >
          <IoIosMenu className="menu-icon " />
        </button>
        <div className="backAndTitle">
          {backButton && (
            <MdKeyboardBackspace
              className="back"
              onClick={handleBackButtonClick}
              size={20}
            />
          )}
          {headerTitle && <p style={{ fontSize: "12px" }}>{headerTitle}</p>}
        </div>
      </div>
      <div
        className={`left-side ${
          pathname === "/" ||
          pathname === "/marketsai" ||
          pathname === "/technicals"
            ? !expanded
              ? "width-25"
              : ""
            : "pl"
        }`}
      >
        {!expanded && (
          <div className="backAndTitleLaptop">
            {backButton && (
              <MdKeyboardBackspace
                className="back"
                onClick={handleBackButtonClick}
                size={28}
              />
            )}
            {headerTitle && <p>{headerTitle}</p>}
          </div>
        )}

        {!expanded && (pathname === "/" || pathname === "/technicals") && (
          <div className="search-bar !hidden  900px:!flex">
            <FiSearch color="#C3C3C3" />
            <input
              onChange={(e) => FindStock(e.target.value)}
              placeholder="Search"
            />
          </div>
        )}
      </div>
      {/* {screenWidth > 425 &&
        (pathname === "/alert" || pathname === "/alertmanual") && (
          <div className="middle-header">
            <Link
              to="/alert"
              className={`${
                pathname === "/alert"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
              }`}
            >
              <p>AI Prompt</p>
            </Link>
            <Link
              to="/alertmanual"
              className={`${
                pathname === "/alertmanual"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
              }`}
            >
              <p>Manual</p>
            </Link>
          </div>
        )} */}
      {screenWidth > 425 &&
        (pathname === "/" ||
          pathname === "/technicals" ||
          pathname === "/marketsai") && (
          <div
            className="middle-header1"
            style={{ display: window.innerWidth < 900 && "none" }}
          >
            {params.get("trader_email") ? (
              <Link
                onClick={handleConfirmTrue}
                to={`/?technicals?trader_email=${params.get("trader_email")}`}
                className={`${
                  window.location.search.includes("technicals")
                    ? "active-middle-item"
                    : "nonactive-middle-item"
                }`}
              >
                <p>Technicals</p>
              </Link>
            ) : (
              <Link
                onClick={() => {
                  if (localStorage.getItem("access_token")) {
                    buttonClick("Technicals nav button");
                  }
                  // handleConfirmTrue();
                }}
                to="/?technicals"
                className={`shrink-0 w-fit h-fit  px-3 py-2 transition border-b-[2px] border-solid  hover: ${
                  window.location.search.includes("technicals")
                    ? "border-b-[#00BA77]"
                    : "border-b-transparent"
                } hover:!bg-white`}
              >
                <p>Technicals</p>
              </Link>
            )}
            {params.get("trader_email") ? (
              <Link
                onClick={handleConfirm}
                to={`/?trader_email=${params.get("trader_email")}`}
                className={`${
                  pathname === "/" &&
                  !window.location.search.includes("technicals")
                    ? "active-middle-item"
                    : "nonactive-middle-item"
                }`}
              >
                <p>Simple</p>
              </Link>
            ) : (
              <Link
                onClick={() => {
                  if (localStorage.getItem("access_token")) {
                    buttonClick("Simple nav button");
                  }
                  handleConfirm();
                }}
                to="/"
                className={`shrink-0 w-fit h-fit  px-3 py-2 transition border-b-[2px] border-solid  hover: ${
                  !window.location.search.includes("technicals")
                    ? "border-b-[#00BA77]"
                    : "border-b-transparent"
                } hover:!bg-white`}
              >
                <p>Simple</p>
              </Link>
            )}
            {/* <Link
              to="/marketsai"
              className={`${pathname === "/marketsai"
                  ? "active-middle-item"
                  : "nonactive-middle-item"
                }`}
            >
              <p>AI</p>
            </Link> */}
          </div>
        )}
      {localStorage.getItem("access_token") ? (
        <div
          className="right-header"
          style={{
            position: "relative",
            paddingRight: `${window.innerWidth < 500 && 0}`,
          }}
        >
          {/* <div className="liveDemoContainer">
          <div
            onClick={() => handleLiveDemo(false)}
            className={`demoDiv ${!liveDemo && "activeLiveDemo"}`}
          >
            <p>Demo</p>
          </div>
          <div
            onClick={() => handleLiveDemo(true)}
            className={`liveDiv ${liveDemo && "activeLiveDemo"}`}
          >
            <p>Live</p>
          </div>
        </div> */}
          <div className=" relative w-[150px] lg:w-[250px]">
            <div
              onClick={(e) => {
                e.stopPropagation();
                buttonClick("Accounts dropdown button");
                setDropdownMenu(false);
                dispatch({ type: "setNotif", payload: false });
                dispatch({ type: "setAccount", payload: !openaccounts });
                setOpenAccountsDropDown(!openAccountsDropDown);
              }}
              className="flex items-center lg:px-[16px] px-[2px] py-[8px] shadow-lg rounded-[5px] my-0 cursor-pointer"
            >
              <div
                className={`${
                  account.activatedAvailable
                    ? "bg-[#B20F19]"
                    : account.acc_type === "demo_portfolio"
                    ? "bg-[#E7A600]"
                    : "bg-[#16392C]"
                } min-h-[32px] min-w-[32px] h-[32px] w-[32px] px-[0.5rem] flex items-center justify-center rounded-full lg:mr-[8px] mr-[4px]`}
              >
                <svg
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="mr-[21px]">
                <h1 className="font-medium text-[0.65rem] lg:text-[1rem] text-[#151515] whitespace-nowrap">
                  {account.acc_name === "demo_account_1"
                    ? "Demo Account"
                    : account.acc_name === "competiton_account_1"
                    ? "Competition Account"
                    : account.acc_name}
                </h1>
                <p className="text-[#989898] text-[0.75rem]">
                  ID: {account.acc_id}
                </p>
              </div>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z"
                  fill="#292D32"
                />
              </svg>
            </div>
            {openaccounts && accounts && (
              <div className="absolute min-h-fit max-h-[300px] overflow-y-scroll top-[54px] lg:top-[60px]  lg:left-0 left-[-3rem] shadow-lg bg-white z-50 rounded-[5px]">
                {/* <div className="flex items-center px-[16px] py-[8px] cursor-pointer border-b border-[#989898]">
              <div className="bg-[#00BA77] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z" fill="white" />
                </svg>
              </div>
              <div className="mr-[21px]">
                <h1 className="font-medium text-[#151515] whitespace-nowrap">Live Account 1</h1>
                <p className="text-[#989898] text-[0.75rem]">ID: 2654816</p>
              </div>
              <svg className="rotate-[270deg]" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z" fill="#292D32" />
              </svg>
            </div> */}
                {accountsLive &&
                  accountsLive.length > 0 &&
                  accountsLive?.map((acc) => (
                    <div
                      key={acc.id}
                      onClick={() => {
                        localStorage.setItem("account_id", acc.account_id);
                        dispatch({
                          type: "select_account",
                          payload: {
                            acc_id: acc.account_id,
                            id: acc.id,
                            acc_type: acc.account_type,
                            acc_name: acc.account_name,
                            activatedAvailable: false,
                          },
                        });
                        dispatch({ type: "setAccount", payload: false });
                        if (params.get("trader_email")) {
                          navigate(
                            `/?trader_email=${params.get("trader_email")}`
                          );
                        } else {
                          navigate("/");
                        }
                      }}
                      className="flex items-center px-[16px] py-[8px] cursor-pointer border-b border-[#989898]"
                    >
                      <div className="bg-[#00BA77] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                        <svg
                          width="10"
                          height="11"
                          viewBox="0 0 10 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="mr-[21px]">
                        <h1 className="font-medium text-[#151515] whitespace-nowrap">
                          {acc.account_name === "demo_account_1"
                            ? "Demo Account"
                            : acc.account_name}
                        </h1>
                        <p className="text-[#989898] text-[0.75rem]">
                          ID: {acc.account_id}{" "}
                        </p>
                      </div>
                      <svg
                        className="rotate-[270deg]"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                  ))}
                {accounts.demo_accounts?.map((acc) => (
                  <div
                    key={acc.id}
                    onClick={() => {
                      localStorage.setItem("account_id", acc.account_id);
                      buttonClick("Demo account select button");
                      dispatch({
                        type: "select_account",
                        payload: {
                          acc_id: acc.account_id,
                          id: acc.id,
                          acc_type: acc.account_type,
                          acc_name: acc.account_name,
                          activatedAvailable: false,
                        },
                      });
                      dispatch({ type: "setAccount", payload: false });
                      if (params.get("trader_email")) {
                        navigate(
                          `/?trader_email=${params.get("trader_email")}`
                        );
                      } else {
                        navigate("/");
                      }
                    }}
                    className="flex items-center px-[16px] py-[8px] cursor-pointer border-b border-[#989898]"
                  >
                    <div className="bg-[#E7A600] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="mr-[21px]">
                      <h1 className="font-medium text-[#151515] whitespace-nowrap">
                        {acc.account_name === "demo_account_1"
                          ? "Demo Account"
                          : acc.account_name}
                      </h1>
                      <p className="text-[#989898] text-[0.75rem]">
                        ID: {acc.account_id}{" "}
                      </p>
                    </div>
                    <svg
                      className="rotate-[270deg]"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                ))}
                {accounts.prop_accounts?.map((acc) => (
                  <div
                    key={acc.id}
                    onClick={() => {
                      if (acc.activated === true) {
                        localStorage.setItem("account_id", acc.account_id);
                        buttonClick("Demo account select button");
                        dispatch({
                          type: "select_account",
                          payload: {
                            acc_id: acc.account_id,
                            id: acc.id,
                            acc_type: acc.account_type,
                            acc_name: acc.account_name,
                            activatedAvailable: true,
                          },
                        });
                        dispatch({ type: "setAccount", payload: false });
                        if (params.get("trader_email")) {
                          navigate(
                            `/?trader_email=${params.get("trader_email")}`
                          );
                        } else {
                          navigate("/");
                        }
                      } else {
                        showRequestFailed(
                          "You can't select a prop account that is not active."
                        );
                      }
                    }}
                    className={`${
                      acc.activated === false ? "opacity-50" : "opacity-100"
                    } flex items-center px-[16px] py-[8px] cursor-pointer border-b border-[#989898]`}
                  >
                    <div className="bg-[#B20F19] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="mr-[21px]">
                      <h1 className="font-medium text-[#151515] whitespace-nowrap">
                        {acc.account_name === "demo_account_1"
                          ? "Demo Account"
                          : acc.account_name}
                      </h1>
                      <p className="text-[#989898] text-[0.75rem]">
                        ID: {acc.account_id}{" "}
                      </p>
                    </div>
                    <svg
                      className="rotate-[270deg]"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                ))}
                {/* <div className="flex items-center px-[16px] py-[8px] cursor-pointer border-b border-[#989898]">
              <div className="bg-[#31705A] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z" fill="white" />
                </svg>
              </div>
              <div className="mr-[21px]">
                <h1 className="font-medium text-[#151515] whitespace-nowrap">Live Account 1</h1>
                <p className="text-[#989898] text-[0.75rem]">ID: 2654816</p>
              </div>
              <svg className="rotate-[270deg]" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z" fill="#292D32" />
              </svg>
            </div> */}
                {accounts.competition_account &&
                  accounts.competition_account.id && (
                    <div
                      onClick={() => {
                        localStorage.setItem(
                          "account_id",
                          accounts.competition_account?.account_id
                        );
                        if (accounts.competition_account?.active === true) {
                          buttonClick("Competition account select button");
                          dispatch({
                            type: "select_account",
                            payload: {
                              acc_id: accounts.competition_account?.account_id,
                              id: accounts.competition_account?.id,
                              acc_type:
                                accounts.competition_account?.account_type,
                              acc_name:
                                accounts.competition_account?.account_name,
                            },
                          });
                          dispatch({ type: "setAccount", payload: false });
                          if (params.get("trader_email")) {
                            navigate(
                              `/?trader_email=${params.get("trader_email")}`
                            );
                          } else {
                            navigate("/");
                          }
                        }
                      }}
                      className={`flex items-center px-[16px] py-[8px] ${
                        accounts.competition_account?.active === false
                          ? "opacity-50 cursor-default"
                          : "opacity-100 cursor-pointer"
                      } border-b border-[#989898]`}
                    >
                      <div className="bg-[#16392C] h-[32px] w-[32px] flex items-center justify-center rounded-full mr-[8px]">
                        <svg
                          width="10"
                          height="11"
                          viewBox="0 0 10 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.150391 3.68226H1.36251V0.0458984H2.57463L4.64736 3.68226H7.42312V0.0458984H8.63524V3.68226H9.84736V4.89438H8.63524V6.1065H9.84736V7.31863H8.63524V10.955H7.42312L5.34433 7.31863H2.57463V10.955H1.36251V7.31863H0.150391V6.1065H1.36251V4.89438H0.150391V3.68226ZM2.57463 3.68226H3.25948L2.57463 2.48832V3.68226ZM2.57463 4.89438V6.1065H4.64736L3.95645 4.89438H2.57463ZM7.42312 8.53075V7.31863H6.72615L7.42312 8.53075ZM5.33827 4.89438L6.03524 6.1065H7.42312V4.89438H5.33827Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="mr-[21px]">
                        <h1 className="font-medium text-[#151515] whitespace-nowrap">
                          {accounts.competition_account?.account_name ===
                          "competiton_account_1"
                            ? "Competition Account"
                            : accounts.competition_account?.account_name}
                        </h1>
                        <p className="text-[#989898] text-[0.75rem]">
                          ID: {accounts.competition_account?.account_id}{" "}
                        </p>
                      </div>
                      <svg
                        className="rotate-[270deg]"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.49958 9.09985C6.12042 9.09985 5.74125 8.9536 5.45417 8.66652L1.9225 5.13485C1.76542 4.97777 1.76542 4.71777 1.9225 4.56068C2.07958 4.4036 2.33958 4.4036 2.49667 4.56068L6.02833 8.09235C6.28833 8.35235 6.71083 8.35235 6.97083 8.09235L10.5025 4.56068C10.6596 4.4036 10.9196 4.4036 11.0767 4.56068C11.2338 4.71777 11.2338 4.97777 11.0767 5.13485L7.545 8.66652C7.25792 8.9536 6.87875 9.09985 6.49958 9.09985Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                  )}
                <Link
                  onClick={() =>
                    dispatch({ type: "setAccount", payload: false })
                  }
                  to="/accounts"
                >
                  <div className="flex items-center justify-center gap-[8px] py-[12px]">
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.65625 0.875C9.0875 0.875 9.4375 1.225 9.4375 1.65625V8.375H16.1562C16.3635 8.375 16.5622 8.45731 16.7087 8.60382C16.8552 8.75034 16.9375 8.94905 16.9375 9.15625C16.9375 9.36345 16.8552 9.56217 16.7087 9.70868C16.5622 9.85519 16.3635 9.9375 16.1562 9.9375H9.4375V16.6562C9.4375 16.8635 9.35519 17.0622 9.20868 17.2087C9.06217 17.3552 8.86345 17.4375 8.65625 17.4375C8.44905 17.4375 8.25034 17.3552 8.10382 17.2087C7.95731 17.0622 7.875 16.8635 7.875 16.6562V9.9375H1.15625C0.94905 9.9375 0.750336 9.85519 0.603823 9.70868C0.45731 9.56217 0.375 9.36345 0.375 9.15625C0.375 8.94905 0.45731 8.75034 0.603823 8.60382C0.750336 8.45731 0.94905 8.375 1.15625 8.375H7.875V1.65625C7.875 1.225 8.225 0.875 8.65625 0.875Z"
                        fill="#00BA77"
                      />
                    </svg>
                    <p className="text-[#00BA77] font-medium">
                      Add New Account
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </div>
          {!params.get("trader_email") && (
            <div className="bell-container">
              {notifs.length > 0 && (
                <div
                  style={{
                    backgroundColor: "red",
                    width: "10px",
                    height: "10px",
                    borderRadius: "100%",
                    position: "absolute",
                    top: "-1px",
                    right: "15px",
                  }}
                ></div>
              )}
              <GoBell
                ref={popupRef}
                onClick={(e) => {
                  e.stopPropagation();
                  buttonClick("Notification button");
                  setOpen(!open);
                  setDropdownMenu(false);
                  dispatch({ type: "setAccount", payload: false });
                  dispatch({ type: "setNotif", payload: !opennotifs });
                }}
                className="bell-icon"
              />
            </div>
          )}
          <div
            // ref={popupRef}
            id="notif"
            style={{
              position: "absolute",
              display: opennotifs ? "block" : "none",
              background: "white",
              height: "200px",
              borderRadius: "10px",
              zIndex: 1000,
              paddingTop: "10px",
            }}
            className="top-[54px] lg:top-[60px] "
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "1.12rem",
                paddingRight: "1.73rem",
              }}
            >
              <p
                style={{
                  fontSize: "1.125rem",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                Notifications
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.75rem",
                }}
              >
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    buttonClick("Mark as read button");
                    marknotif();
                  }}
                >
                  Mark all as read
                </p>
                <svg
                  onClick={() =>
                    dispatch({ type: "setNotif", payload: !opennotifs })
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                >
                  <rect
                    width="17.3333"
                    height="16"
                    rx="3"
                    fill="#D9D9D9"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M11.3337 5.33301L6.00037 10.6663"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.00037 5.33301L11.3337 10.6663"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingLeft: "1.12rem",
                paddingRight: "2.56rem",
                marginTop: "0.5rem",
                paddingBottom: "1rem",
              }}
            >
              {notifs
                ?.filter((notification, index, self) => {
                  // Check if the index of the current notification is the first occurrence of its notification_id
                  return (
                    self.findIndex(
                      (n) => n.notification_id === notification.notification_id
                    ) === index
                  );
                })
                .filter((ret) => ret.timestamp)
                .slice(0, 30)
                .map((notif) => (
                  <div
                    key={notif.notification_id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    {notif.is_read && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        className="mt-2"
                      >
                        <circle cx="4" cy="4" r="4" fill="#359602" />
                      </svg>
                    )}
                    <div
                      style={{
                        marginLeft: !notif.is_read && "1.5rem",
                        borderBottom: "1px solid #D9D9D9",
                        paddingBottom: "0",
                        width: "90%",
                      }}
                    >
                      <p
                        style={{
                          color: "#434343",
                          fontSize: "0.875rem",
                          marginBottom: "0.75rem",
                        }}
                      >
                        {notif.message}
                      </p>
                      <p style={{ color: "#43434380", fontSize: "0.75rem" }}>
                        {ConvertData(notif.timestamp)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            {notifs.length <= 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "1.5rem",
                  color: "#9ca3af",
                }}
              >
                There are no notifications at the moment.
              </p>
            )}
          </div>

          {!params.get("trader_email") && (
            <div ref={dropDownRef} className="profile-dropdown">
              <div
                onClick={() => {
                  buttonClick("Profile dropdown button");
                  setDropdownMenu(!dropdownMenu);
                  dispatch({ type: "setNotif", payload: !opennotifs });
                  dispatch({ type: "setAccount", payload: false });
                }}
                className="profile-imageContainer"
              >
                <img
                  src={
                    profileImg !== "" && profileImg !== undefined
                      ? profileImg
                      : profilePicture
                  }
                  alt="Profile"
                  className="profile-image"
                />
                <FaChevronDown className="dropdown-icon" />
              </div>
            </div>
          )}
          {dropdownMenu && (
            <div className="dropdown-content !top-[54px] !lg:top-[64px]">
              {dropdown.map((item, index) => (
                <Link
                  to={item.path}
                  onClick={() => {
                    buttonClick(`${item.name} nav button`);
                    handleDropDownClick(item);

                    if (item.path === "/profile") handleTabClick("/profile");
                  }}
                  key={index}
                  className="dropdown-item"
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    className="dropdown-img"
                  />
                  <p>{item.name}</p>
                </Link>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="flex">
          <button
            onClick={() => (window.location.href = "/register")}
            className="border border-[#00ba77] rounded-lg text-[#00ba77] font-medium"
          >
            Sign Up
          </button>
          <button
            onClick={() => (window.location.href = "/login")}
            className="bg-[#00ba77] text-white rounded-lg border border-[#00ba77] font-medium"
          >
            Sign In
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
