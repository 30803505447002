/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./dayslock.css";
import Vector from "../../assets/images/Vector.svg";
// import Calendar from "../../assets/images/calendar.svg";
import { useSearchParams } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DaysLock = () => {
  const [searchParams] = useSearchParams()
  const duration = searchParams.get("days")
  const amt = searchParams.get("amount")
  // const [startDate, setStartDate] = useState("");
  // const [open, setOpen] = useState(false)
  const [details, setDetails] = useState({
    amount: "",
    title: ""
  })
  const [loading, setLoading] = useState(false)

  // console.log(details)

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white"
      }
    });
  };

  useEffect(() => {
    setDetails({ ...details, amount: amt })
  }, [amt])


  const createSavingsLock = async () => {
    setLoading(true)
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    try {
      axios.post("lock/savings/", {
        title: details.title,
        source: "wallet",
        amount: details.amount,
        duration: duration,
        device_type:deviceType
      }).then(res => {
        console.log(res)
        showRequestSuccess("Successfully created savings lock.")
        setLoading(false)
      }).catch(err => {
        console.log(err)
        if (err.response.data.error === 'Insufficient balance in the wallet.') {
          showRequestFailed("Insufficient balance in your wallet.")
          setLoading(false)
        } else {
          showRequestFailed("Failed to create savings lock.")
          setLoading(false)
        }
      })
    } catch (error) {

    }
  }

  return (
    <div className="rounded-card-container">
      <ToastContainer />
      <div className="rounded-card">
        <div className="daysLockCard">
          <div className="daysLockHeading">
            <h4>{duration} days lock</h4>
          </div>
          <div className="dayslockText">
            <p>
              Lorem ipsum dolor sit amet consectetur. Augue aliquet gravida
              commodo faucibus
            </p>
          </div>
          <div className="amountToLock">
            <p>Amount to lock</p>
            <div className="amountValue" style={{ display: "flex", gap: "10px" }}>
              <p>N</p>
              <input value={details.amount} type="number" style={{ width: "100%", border: "none", outline: "none" }} onChange={(e) => setDetails({ ...details, amount: e.target.value })} />
            </div>
          </div>
          <div className="titleToLock">
            <p>Title of lock</p>
            <input type="text" className="titleValue" onChange={(e) => setDetails({ ...details, title: e.target.value })} />
          </div>
          <div className="sourceOfFunding">
            <p>Source of funding</p>
            <div className="funding">
              <p>Wallet</p>
              <img src={Vector} alt="funding" />
            </div>
          </div>
          {/* <div className="paybackDate">
            <p>Payback date</p>
            <div className="payback">
              <DatePicker onBlur={() => setOpen(!open)} onFocus={() => setOpen(!open)} className="datePicker" open={open} selected={startDate} onChange={(date) => setStartDate(date)} />
              <img onClick={() => setOpen(!open)} src={Calendar} alt="pay back" style={{ cursor: "pointer" }} />
            </div>
          </div> */}
          <div onClick={() => !loading && createSavingsLock()} className="daysLockButton">
            <p>{loading ? "Creating..." : "Complete"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaysLock;
