import React from "react";
import ApexCharts from "react-apexcharts";

function TreeMapChart({ data }) {
  console.log(data);
  const settings = {
    series: [
      {
        data:
          data?.length > 0
            ? data.map((hold) => ({
                x: hold.symbol || hold.stock,
                y: hold.profit_loss,
              }))
            : [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: "100%",
        width:"100%",
        type: "treemap",
        toolbar: {
          show: false,
        },
      },
      // title: {
      //     text: 'Distibuted Treemap (different color for each cell)',
      //     align: 'center'
      // },
      colors: [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
        "#aec7e8",
        "#ffbb78",
        "#98df8a",
        "#ff9896",
        "#c5b0d5",
        "#c49c94",
        "#f7b6d2",
        "#c7c7c7",
        "#dbdb8d",
        "#9edae5",
        "#393b79",
        "#637939",
        "#8c6d31",
        "#843c39",
        "#7b4173",
        "#5254a3",
        "#637939",
        "#8c6d31",
        "#843c39",
        "#7b4173",
        "#5254a3",
        "#393b79",
        "#637939",
        "#8c6d31",
        "#843c39",
        "#7b4173",
        "#5254a3",
        "#393b79",
        "#637939",
        "#8c6d31",
        "#843c39",
        "#7b4173",
        "#5254a3",
        "#393b79",
        "#637939",
        "#8c6d31",
        "#843c39",
        "#7b4173",
        "#5254a3",
        "#393b79",
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    },
  };
  return (
    <ApexCharts
      options={settings.options}
      series={settings.series}
      type="treemap"
      height={"100%"}
      width={"100%"}
    />
  );
}

export default TreeMapChart;
