import React, { useState } from "react";
import "./banktransfer.css";
import Icon from "../../assets/images/document-copy.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import UploadSuccess from "../../assets/OBJECTS.svg"
import { Link } from "react-router-dom";


const BankTransfer = () => {
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [file, setFile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  console.log(window.location.search)

  // const showRequestSuccess = (message) => {
  //   toast.success(message, {
  //     position: toast.POSITION.BOTTOM_CENTER,
  //   });
  // };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const uploadPhoto = async () => {
    setLoading(true);
    let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
    let formdata = new FormData();
    if (file) {
      formdata.append("image", file);
      formdata.append("device_type", deviceType);
    }
    try {
      axios
        .post("portfolio/receipts/", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setRefresh(!refresh);
          dispatch({ type: "refresh" });
          setImageBase64("");
          window.location.search = "?successful=yes"

        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showRequestFailed("Failed to send receipt");
        });
    } catch (error) {
      console.log(error);
    }
  };

  function handleFileUpload(event) {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        showRequestFailed(
          "File size is too big, please choose a smaller file."
        );
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64String = e.target.result;
          setImageBase64(base64String);
        };

        reader.readAsDataURL(file);
        setFile(file);
      }
    }
  }

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };

  return (
    <div className="rounded-card-container">
      <ToastContainer />
      <div className="rounded-card">
        {window.location.search !== "?successful=yes" && <div className="bankTransferContainer">
          <div className="bankTransferHeading">
            <h4>Bank Transfer</h4>
          </div>
          <div className="bankTransferText">
            <p>
              Make a transfer to the account details below and click the button to upload your receipt.
            </p>
          </div>
          <div className="detailCard">
            <div className="bankCard">
              <p>Bank name</p>
              {copied && <span className="tooltip">Copied!</span>}
              <div className="bankName">
                <p>Paystack-Titan</p>
                <img
                  onClick={() => handleCopyClick("Paystack-Titan")}
                  src={Icon}
                  alt="copy icon 1"
                />
              </div>
            </div>
            <div className="accountNoCard">
              <p>Account Number</p>
              <div className="accountNo">
                <p>9982663552</p>
                <img
                  onClick={() => handleCopyClick("9982663552")}
                  src={Icon}
                  alt="copy icon 2"
                />
              </div>
            </div>
            <div className="accountNameCard">
              <p>Account name</p>
              <div className="accountName">
                <p>Asokorotechno/hamma</p>
                <img
                  onClick={() => handleCopyClick("Asokorotechno/hamma")}
                  src={Icon}
                  alt="copy icon 3"
                />
              </div>
            </div>
          </div>
          {imageBase64 ? (
            <div>
              <img src={imageBase64} alt="" className="viewimg" />
              <div
                onClick={() => !loading && uploadPhoto()}
                className="paymentButton"
              >
                <p>{loading ? "Sending..." : "Send"}</p>
              </div>
            </div>
          ) : (
            <label htmlFor="proofOfPayment" className="paymentButton">
              <p>Upload Proof of payment</p>
            </label>
          )}
          <input
            onChange={(e) => handleFileUpload(e)}
            id="proofOfPayment"
            type="file"
            style={{ display: "none" }}
          />
        </div>}
        {window.location.search === "?successful=yes" && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8.94rem", paddingBottom: "10.38rem" }}>
          <img src={UploadSuccess} alt="" width={300} height={300} />
          <h1>Upload Successful</h1>
          <p style={{ textAlign: "center" }}>You will be notified once your payment has been verified!</p>
          <Link to="/">
            <button style={{ background: "#00BA77", border: "1px solid #80808080", display: "flex", alignItems: "center", color: "white", gap: "0.62rem", padding: "1rem 6rem", borderRadius: "0.3125rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 9.99994C18.75 9.83418 18.6841 9.67521 18.5669 9.558C18.4497 9.44079 18.2907 9.37494 18.125 9.37494H3.38374L7.31749 5.44244C7.43485 5.32508 7.50078 5.16591 7.50078 4.99994C7.50078 4.83397 7.43485 4.6748 7.31749 4.55744C7.20013 4.44008 7.04096 4.37415 6.87499 4.37415C6.70902 4.37415 6.54985 4.44008 6.43249 4.55744L1.43249 9.55744C1.37428 9.6155 1.32811 9.68447 1.2966 9.7604C1.26509 9.83633 1.24887 9.91773 1.24887 9.99994C1.24887 10.0822 1.26509 10.1636 1.2966 10.2395C1.32811 10.3154 1.37428 10.3844 1.43249 10.4424L6.43249 15.4424C6.4906 15.5006 6.55958 15.5466 6.63551 15.5781C6.71143 15.6095 6.79281 15.6257 6.87499 15.6257C7.04096 15.6257 7.20013 15.5598 7.31749 15.4424C7.43485 15.3251 7.50078 15.1659 7.50078 14.9999C7.50078 14.834 7.43485 14.6748 7.31749 14.5574L3.38374 10.6249H18.125C18.2907 10.6249 18.4497 10.5591 18.5669 10.4419C18.6841 10.3247 18.75 10.1657 18.75 9.99994Z" fill="#F1F1F1" />
              </svg>
              <p>Return to Home</p>
            </button>
          </Link>
        </div>}
      </div>
    </div>
  );
};

export default BankTransfer;
