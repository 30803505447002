import React from "react";
import "./buyselllot.css";
import { useDispatch } from "react-redux";
import axios from "../../axios";

const BuySellLot = ({ showPopup, reset, handleDecrement, handleClick, handleIncrement, setActiveButton, value, setValue, setLotName }) => {
        // const expanded = useSelector((state) => state.page.expanded);
        const dispatch = useDispatch();


        const buttonClick = async (buttonName) => {
                // console.log(buttonName)
                // console.log(page.toLowerCase)
            let deviceType = window.innerWidth <= 768 ? "Mobile" : window.innerWidth <= 1024 ? "Tablet" : "Desktop"
                try {
                        axios
                                .post("/portfolio/button-click/", {
                                        button_name: `${buttonName}`,
                                        page: window.location.pathname.split("/")[1] || "home",
                             device_type:deviceType
                                })
                                .then((res) => {
                                        console.log(res);
                                })
                                .catch((err) => {
                                        console.log(err);
                                });
                } catch (error) {
                        console.log(error);
                }
        };


        return (
                <div className={`middleHeader`} style={{ alignItems: "center" }}>
                        <div onClick={() => {
                                console.log("clicked")
                                if (window.innerWidth < 700) {
                                        buttonClick("Buy button")
                                        setActiveButton("buy")
                                        dispatch({ type: "setConfirm", payload: true });
                                        handleClick()
                                } else {
                                        buttonClick("Buy button")
                                        setActiveButton("buy")
                                        dispatch({ type: "setConfirm", payload: true });
                                }
                        }} className="buySmallButton" style={{ height: "3.7rem",  }}>
                                <p>Buy</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <select onChange={(e) => {
                                        console.log(e.target.value)
                                        setLotName(e.target.value)
                                        if (e.target.value === "standard") {
                                                setValue(1)
                                        } else if (e.target.value === "mini") {
                                                setValue(0.1)
                                        } else if (e.target.value === "micro") {
                                                setValue(0.01)
                                        } else if (e.target.value === "nano") {
                                                setValue(0.001)
                                        }
                                        }} className="outline-none rounded" name="" id="" style={{ margin: "5px auto", border: "1px solid #808080E5",  padding: "0.25rem" }} 
                                >
                                        <option value="nano">Nano</option>
                                        <option value="micro">Micro</option>
                                        <option value="mini">Mini</option>
                                        <option value="standard">Standard</option>
                                </select>
                                <div className="lotCalculation" style={{ width: "165px" }}>

                                        <p>Lot</p>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <button style={{ margin: 0, width: "33.3%", padding: "8px" }} onClick={handleIncrement}>+</button>
                                                <input style={{ width: "33.3%" }} type="text" value={value} onChange={(e) => setValue(e.target.value.replace(/[^0-9.]/g, ''))} />
                                                <button style={{ margin: 0, width: "33.3%", padding: "8px" }} onClick={handleDecrement}>-</button>
                                        </div>
                                </div>
                                <p className="mb-2.5" style={{ textAlign: "center", marginTop: "0.25rem", color: "#0FB23C", padding: "0.38rem 0.50rem", background: "#0FB23C1A", borderRadius: "0.125rem" }}>{(value * 100000).toLocaleString()} units</p>
                        </div>
                        <div onClick={() => {
                                if (window.innerWidth < 700) {
                                        buttonClick("Sell button")
                                        setActiveButton("sell")
                                        dispatch({ type: "setConfirm", payload: true });
                                        handleClick()
                                } else {
                                        buttonClick("Sell button")
                                        setActiveButton("sell")
                                        dispatch({ type: "setConfirm", payload: true });
                                }
                        }} className="sellSmallButton" style={{ height: "3.7rem",  }}>
                                <p>Sell</p>
                        </div>
                </div>
        );
};

export default BuySellLot;
