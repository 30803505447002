import React from "react";
import ReactApexChart from "react-apexcharts";

function LineChart({ overviewPort, overviewMark, labels }) {
  console.log(overviewPort);
  console.log(overviewMark);
  console.log(labels);
  let series = [
    {
      name: "NGX all share index",
      data: overviewMark,
    },
    {
      name: "Portfolio",
      data: overviewPort,
    },
  ];

  let options = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    // plotOptions: {
    //   bar: {
    //     horizontal: false,
    //     columnWidth: "30%",
    //     endingShape: "rounded",
    //   },
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#00ba77", "#ffa500"],
    xaxis: {
      categories: labels,
      tickAmount:10,
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    // yaxis: {
    //   title: {
    //     text: "% (percent)",
    //   },
    // },
    // fill: {
    //   opacity: 1,
    // },
    tooltip: {
      y: {
        formatter: function (val) {
          if (val === 0) {
            return "0%";
          } else {
            return val + "%";
          }
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default LineChart;
