import React from "react";
import ReactApexChart from "react-apexcharts";

function ColumnChart({ overviewPort, overviewMark, labels }) {
  console.log(overviewPort);
  console.log(overviewMark);
  console.log(labels);
  let series = [
    {
      name: "NGX all share index",
      data: overviewMark,
    },
    {
      name: "Portfolio",
      data: overviewPort,
    },
  ];

  let options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ['#00ba77', '#ffa500'],
    xaxis: {
      categories: labels,
      labels: {
        style:{
          fontSize:"12px"
        }
      }
    },
    yaxis: {
      title: {
        text: "% (percent)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ColumnChart;
