import React, { useEffect, useMemo, useState } from "react";
import "./createeditalert.css";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LuHistory } from "react-icons/lu";

const CreateEditAlert = ({ title }) => {
  const [symbols, setSymbols] = useState([]);
  const [alert, setAlert] = useState({
    stock: "",
    alert_name: "",
    description: "",
    alert_type: "",
    action: "",
    to_price: null,
    to_volume: null,
    frequency: "",
  });
  const [stock, setStock] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [use] = useSearchParams();
  const id = use.get("id");
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  // console.log(id)

  // console.log(alert)

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        backgroundColor: "black",
        color: "white",
      },
    });
  };

  const createAlert = async () => {
    setLoading(true);
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    let data = { ...alert, device_type: deviceType };
    if (id) {
      try {
        axios
          .put(`portfolio/alert/${id}/`, data)
          .then((res) => {
            console.log(res);
            dispatch({ type: "refresh" });
            setLoading(false);
            showRequestSuccess("Successfully updated alert");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            if (
              err.response.data.detail ===
              "You have reached the maximum limit of 10 alerts for this month."
            ) {
              showRequestFailed(
                "You have reached the maximum limit of 10 alerts for this month."
              );
            } else {
              showRequestFailed("Failed to update alert");
            }
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        axios
          .post("portfolio/alert/", data)
          .then((res) => {
            console.log(res);
            dispatch({ type: "refresh" });
            setLoading(false);
            showRequestSuccess("Successfully created alert");
            setAlert({
              stock: "",
              alert_name: "",
              description: "",
              alert_type: "",
              action: "",
              to_price: null,
              to_volume: null,
              frequency: "",
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            if (
              err.response.data.detail ===
              "You have reached the maximum limit of 10 alerts for this month."
            ) {
              showRequestFailed(
                "You have reached the maximum limit of 10 alerts for this month."
              );
            } else {
              showRequestFailed("Failed to create alert");
            }
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    axios
      .get("portfolio/symbols/")
      .then((res) => {
        // console.log(res)
        setSymbols(
          res.data.map((item) => {
            return { symbol: Object.keys(item), id: Object.values(item) };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("portfolio/alert/")
      .then((res) => {
        console.log(res.data);
        // console.log(res.data.filter(alert => alert.id.toString() === id))
        if (id) {
          setAlert({
            stock: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].stock_id,
            alert_name: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].alert_name,
            description: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].description,
            alert_type: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].alert_type,
            action: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].action,
            to_price: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].to_price,
            to_volume: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].to_volume,
            frequency: res.data.alerts.filter(
              (alert) => alert.id.toString() === id
            )[0].frequency,
          });
          setStock(
            res.data.alerts.filter((alert) => alert.id.toString() === id)[0]
              .stock
          );
        } else {
          setAlert({
            stock: "",
            alert_name: "",
            description: "",
            alert_type: "",
            action: "",
            to_price: null,
            to_volume: null,
            frequency: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div className="alert-manual pt-6 sm:pt-[32px] mb-[100px]">
      <ToastContainer />
      <div className="alert-manual-card">
        <div className="create-alert-heading relative">
          <div
            onClick={() => dispatch({ type: "setHistory", payload: true })}
            className="absolute -top-[1.6rem] -translate-y-1/2 left-0 flex items-center gap-1 cursor-pointer"
          >
            <LuHistory />
            <p>Alert History</p>
          </div>
          <h4>{title}</h4>
        </div>
        <div className="alert-name">
          <p>Name</p>
          <div className="nameSelect">
            <input
              className="nameInput"
              type="text"
              rows={3}
              multiple={true}
              style={{ fontFamily: "Space Grotesk" }}
              placeholder=""
              onChange={(e) =>
                setAlert({ ...alert, alert_name: e.target.value })
              }
              value={alert.alert_name}
            />
          </div>
        </div>
        <div className="alert-discription">
          <p>Description</p>
          <div className="discription-text">
            <textarea
              value={alert.description}
              onChange={(e) =>
                setAlert({ ...alert, description: e.target.value })
              }
              placeholder="Type your text here..."
              rows={2}
              cols={40}
              style={{ fontFamily: "Space Grotesk" }}
            />
          </div>
        </div>
        <div className="alert-instrument">
          <p>Instrument</p>
          <div className="instrumentSelect">
            <select
              value={alert.stock}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                fontFamily: "Space Grotesk",
              }}
              onChange={(e) => setAlert({ ...alert, stock: e.target.value })}
              id="Instrument"
            >
              <option style={{ margin: "20px" }} selected disabled value="">
                {alert.stock === null ? "Choose Instrument" : stock}
              </option>
              {symbols.map((symbol) => (
                <option
                  style={{ margin: "20px" }}
                  key={symbol.id[0]}
                  value={symbol.id[0]}
                >
                  {symbol.symbol[0]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="alert-price">
          <p>Alert Type</p>
          <div className="price-mid">
            <div className="price-select">
              <select
                value={alert.alert_type}
                style={{ fontFamily: "Space Grotesk" }}
                onChange={(e) =>
                  setAlert({ ...alert, alert_type: e.target.value })
                }
                id="Price"
              >
                <option disabled selected value="">
                  {alert.alert_type === ""
                    ? "Choose alert type"
                    : alert.alert_type}
                </option>
                <option value="price">Price</option>
                <option value="volume">Volume</option>
              </select>
            </div>
            <div className="above-select">
              <select
                value={alert.action}
                style={{ fontFamily: "Space Grotesk" }}
                onChange={(e) => setAlert({ ...alert, action: e.target.value })}
                id="above"
              >
                <option disabled selected value="">
                  {alert.action === "" ? "Choose action" : alert.action}
                </option>
                <option value="above">above</option>
                <option value="below">below</option>
              </select>
            </div>
            <div className="price-input">
              <input
                onChange={(e) => {
                  if (alert.alert_type === "price") {
                    setAlert({ ...alert, to_price: e.target.value });
                  } else {
                    setAlert({ ...alert, to_volume: e.target.value });
                  }
                }}
                type="number"
                value={
                  alert.to_price
                    ? alert.to_price
                    : alert.to_volume
                    ? alert.to_volume
                    : ""
                }
                placeholder=""
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {/* <div className="alertPriceBottom">
            <p>%change</p>
          </div> */}
        </div>
        {/* <div className="alert-volume">
          <p>Volume</p>
          <div className="volume-mid">
            <div className="price-select">
              <select id="Price">
                <option value="Price">Price</option>
              </select>
            </div>
            <div className="above-select">
              <select id="above">
                <option value="above">above</option>
              </select>
            </div>
            <div className="volume-input">
              <input type="text" placeholder="" />
            </div>
          </div>
        </div> */}
        <div className="alert-schedule">
          <p>Schedule</p>
          <div className="scheduleSelect">
            <select
              value={alert.frequency}
              style={{ fontFamily: "Space Grotesk" }}
              onChange={(e) =>
                setAlert({ ...alert, frequency: e.target.value })
              }
              id="Schedule"
            >
              <option disabled selected value="">
                {alert.frequency === "" ? "Choose schedule" : alert.frequency}
              </option>
              <option value="Once">Once</option>
              <option value="Everytime">Everytime</option>
            </select>
          </div>
        </div>
        {!params.get("trader_email") && (
          <div className="alertManualButton">
            <div
              onClick={() => createAlert()}
              className="proceedButton"
              style={{ marginLeft: "auto" }}
            >
              <p>{loading ? "Creating..." : id ? "Update" : "Proceed"}</p>
            </div>
            {/* <div className="cancelButton">
            <p>Cancel</p>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEditAlert;
